import Button from "immigram-ui-cmon/src/components/Button/Button";
import Heading, { HeadingTag } from "immigram-ui-cmon/src/components/Heading/Heading";
import Paragraph from "immigram-ui-cmon/src/components/Paragraph/Paragraph";
import * as React from "react";
const NotFoundPage = () => {
  return <main>
      <section className="bg-white-900">
        <div className="layout flex min-h-screen flex-col items-center justify-center text-center text-stone-800">
          <img className="w-[215px] rounded-[50%]" src="/images/gif/cat-404.gif" alt="cat" />
          <Heading tag={HeadingTag.H3} className="mb-4">
            This page doesn’t exist
          </Heading>
          <Paragraph>
            Go to the dashboard, it is usually easy to find any other page
          </Paragraph>
          <Button href="/" className="mt-4 md:text-lg" size="250">
            Go to dashboard
          </Button>
        </div>
      </section>
    </main>;
};
export default NotFoundPage;